// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-reference-js": () => import("./../../../src/pages/api-reference.js" /* webpackChunkName: "component---src-pages-api-reference-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-become-partner-js": () => import("./../../../src/pages/become-partner.js" /* webpackChunkName: "component---src-pages-become-partner-js" */),
  "component---src-pages-budget-integration-js": () => import("./../../../src/pages/budget-integration.js" /* webpackChunkName: "component---src-pages-budget-integration-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-gitbook-js": () => import("./../../../src/pages/gitbook.js" /* webpackChunkName: "component---src-pages-gitbook-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-doc-js": () => import("./../../../src/templates/doc.js" /* webpackChunkName: "component---src-templates-doc-js" */)
}

