import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import "./src/styles/index.less"

const onRedirectCallback = (appState) => {
	// Use Gatsby's navigate method to replace the url
	navigate(appState?.returnTo || '/', { replace: true });
};

export const wrapRootElement = ({ element }) => {
	return (
		<Auth0Provider
			domain="strands-developer-portal.eu.auth0.com"
			clientId="psBZQ8S3TC9uQnzalrqHCN3FqI5OFFnQ"
			redirectUri={(typeof window !== "undefined" && window.location) ? window.location.protocol + "//" + window.location.host + "/app" : ""}
			onRedirectCallback={onRedirectCallback}
		>
			{element}
		</Auth0Provider>
	);
};